<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Swal from "sweetalert2";
import axios from "axios";
/**
 * Dashboard Component
 */
export default {
    page: {
        title: "Add Work Permit Type",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        PageHeader,
    },
    data() {
        return {
            title: "Add Work Permit Type",
            items: [
                {
                    text: "Master",
                    href: "/",
                },
                {
                    text: "Work Permit Type",
                    href: "/master/sub-prizinan",
                },
                {
                    text: "Add Work Permit Type",
                    active: true,
                },
            ],

            // variabel untuk add data
            mps_nama: "",
        };
    },
    mounted() {},
    methods: {
        // Method dari masing masing fungsi di dalam file ini
        StoreData() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });

            var config_store_data = {
                method: "post",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/perizinan-sub",
                data: {
                    mps_nama: self.mps_nama,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config_store_data)
                .then(function (response) {
                    console.log(response);
                    let timerInterval;
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Anda akan diarahkan ke halaman master Work Permit Type segera",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                        willClose: () => {
                            clearInterval(timerInterval);
                        },
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            self.$router.push({ name: "all-sub-perizinan" });
                        }
                    });
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <b-form class="p-2" @submit.prevent="StoreData">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <b-form-group class="mb-3" label="Name Work Permit Type" label-for="formrow-nama-sub-perizinan-input">
                                        <b-form-input id="formrow-nama-sub-perizinan-input" type="text" v-model="mps_nama"></b-form-input>
                                    </b-form-group>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="text-end">
                                <b-button type="submit" variant="primary"><i class="fa fa-save"></i> Simpan</b-button>
                            </div>
                        </div>
                    </b-form>
                </div>
            </div>
        </div>
    </Layout>
</template>
